import {
  DeliveryShiftSchedule,
  PackagingCategory,
  SalesPackagingControlReportItemWhereInput,
  useOutletsQuery,
  useSalesPackagingControlReportItemsQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import DataTable from '../common/DataTable';
import DateFilter from '../common/DateFilter';
import EnumSearchFilter from '../common/EnumSearchFilter';
import Search from '../common/Search';
import SearchFilter from '../common/SearchFilter';
import ListView from '../templates/ListView';

const SalesPackagingControlReportItemsPage = ({
  title = 'Sales Packaging Control Report',
}) => {
  const [
    deliveryShiftScheduleQueryString,
    dateQueryString,
    outletIdQueryString,
  ] = useGetQueryString(['deliveryShiftSchedule', 'date', 'outletId']);

  const where: SalesPackagingControlReportItemWhereInput = Object.assign(
    {},
    typeof dateQueryString === 'string'
      ? {
          date: dateQueryString,
        }
      : {},

    deliveryShiftScheduleQueryString
      ? {
          deliveryShiftSchedule:
            deliveryShiftScheduleQueryString as DeliveryShiftSchedule,
        }
      : {},
    typeof outletIdQueryString === 'string'
      ? {
          outletId: +outletIdQueryString,
        }
      : {}
  );

  const variables = {
    where,
  };

  const {
    data: { salesPackagingControlReportItems } = {},
    loading,
    error,
  } = useSalesPackagingControlReportItemsQuery({
    fetchPolicy: 'network-only',
    variables,
    skip: !where?.date || !where.deliveryShiftSchedule || !where.outletId,
  });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof salesPackagingControlReportItems>>>
  > = [
    {
      header: 'Category',
      accessor: 'category',
      Cell: (children) => getEnumKeyFromValue(PackagingCategory, children),
    },
    {
      header: 'Packaging',
      accessor: 'packaging',
    },

    {
      header: 'Unit',
      accessor: 'unit',
    },
    {
      header: 'Beginning',
      accessor: 'beginningQuantity',
    },
    {
      header: 'Deliveries',
      accessor: 'deliveriesQuantity',
    },
    {
      header: 'Transfer In',
      accessor: 'transferInQuantity',
    },
    {
      header: 'Transfer Out',
      accessor: 'transferOutQuantity',
    },
    {
      header: 'EM',
      accessor: 'emQuantity',
    },
    {
      header: 'SD',
      accessor: 'sdQuantity',
    },
    {
      header: 'TA',
      accessor: 'taQuantity',
    },
    {
      header: 'Ending Inventory',
      accessor: 'endingQuantity',
    },
    {
      header: 'Usage',
      accessor: 'usage',
    },
    {
      header: 'CRT Sold',
      accessor: 'crtPackagingItemQuantity',
    },
    {
      header: 'Variance',
      accessor: 'variance',
    },
  ];
  const { data: { outlets } = {}, loading: outletsLoading } = useOutletsQuery({
    fetchPolicy: 'network-only',
  });

  return (
    <ListView
      loading={loading || outletsLoading}
      error={error}
      title={title}
      filters={
        <>
          <DateFilter dateField="date" label="Date" />
          <EnumSearchFilter
            label="Delivery Shift Schedule"
            field="deliveryShiftSchedule"
            enumObj={DeliveryShiftSchedule}
          />
          <SearchFilter
            label="Outlet"
            field="outletId"
            arr={outlets}
            setAccessor={(value) => value.name}
            setValue={(value) => value.id}
          />
        </>
      }
      dataTable={
        <DataTable data={salesPackagingControlReportItems} columns={columns} />
      }
    />
  );
};

export default SalesPackagingControlReportItemsPage;
