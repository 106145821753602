import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";

const link = createHttpLink({
  uri: `${process.env.REACT_APP_BACKEND_URI}`,
  credentials: "include",
  headers: {
    "Apollo-Require-Preflight": "true",
  },
});

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

export default apolloClient;
