import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import {
  DeliveryShiftSchedule,
  DonutEndingConsolidationWhereInput,
  useTotalDeliveryReceiptSummaryQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import DateFilter from '../common/DateFilter';
import EnumSearchFilter from '../common/EnumSearchFilter';
import ListView from '../templates/ListView';

const TotalDeliveryReceiptSummaryPage = ({
  title = 'Total Delivery Receipt Summary',
}) => {
  const [dateQueryString, deliveryShiftScheduleQueryString, searchQueryString] =
    useGetQueryString(['date', 'deliveryShiftSchedule', 'search']);

  const where: DonutEndingConsolidationWhereInput = Object.assign(
    {},
    deliveryShiftScheduleQueryString
      ? {
          deliveryShiftSchedule:
            deliveryShiftScheduleQueryString as DeliveryShiftSchedule,
        }
      : {},
    typeof dateQueryString === 'string'
      ? {
          date: new Date(dateQueryString),
        }
      : {},
    typeof searchQueryString === 'string'
      ? {
          searchTerm: searchQueryString,
        }
      : {}
  );

  const variables = {
    where,
  };

  const {
    data: { totalDeliveryReceiptSummary } = {},
    loading,
    error,
  } = useTotalDeliveryReceiptSummaryQuery({
    fetchPolicy: 'network-only',
    variables,
    skip: !dateQueryString || !deliveryShiftScheduleQueryString,
  });

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        filters={
          <>
            <DateFilter dateField="date" label="Date" />
            <EnumSearchFilter
              label="Delivery Shift Schedule"
              field="deliveryShiftSchedule"
              enumObj={DeliveryShiftSchedule}
            />
          </>
        }
        dataTable={
          <>
            <Box sx={{ m: 2 }}>
              <Box
                sx={{
                  display: 'grid',
                  // gridAutoFlow: 'column',
                  alignItems: 'start',
                  gridTemplateColumns: 'repeat(4, 1fr)',
                  gap: 1,
                }}
              >
                {totalDeliveryReceiptSummary?.map((summaryItem) => (
                  <Table size="small" sx={{ border: `1px solid ${grey[300]}` }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          {summaryItem.donutMixAndCut}
                        </TableCell>
                        <TableCell align="center">QTY</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {summaryItem.deliveryReceiptSummaries?.map(
                        (deliveryReceiptSummary) => (
                          <TableRow>
                            <TableCell align="center">
                              {deliveryReceiptSummary.cmlProduct?.name}
                            </TableCell>
                            <TableCell align="center">
                              {deliveryReceiptSummary.quantity}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                ))}
              </Box>
              <Typography
                variant="h6"
                align="center"
                sx={{
                  backgroundColor: (theme) => theme.palette.secondary.main,
                  color: 'white',
                  textTransform: 'uppercase',
                  my: 2,
                }}
              >
                Summary For Production Report
              </Typography>
              <Box
                sx={{
                  display: 'grid',
                  // gridAutoFlow: 'column',
                  gridTemplateColumns: 'repeat(4, 1fr)',
                  gap: 2,
                }}
              >
                {totalDeliveryReceiptSummary?.map((summaryItem) => (
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(2, 1fr)',
                      border: `1px solid ${grey[300]}`,
                      gap: 2,
                      alignItems: 'center',
                      // padding: 2,
                    }}
                  >
                    <Typography align="center" sx={{ fontWeight: 'bold' }}>
                      {summaryItem.donutMixAndCut}
                    </Typography>
                    <Typography align="center" sx={{ fontWeight: 'bold' }}>
                      {summaryItem.deliveryReceiptSummaries?.reduce(
                        (acc, cur) => {
                          return acc + (cur.quantity || 0);
                        },
                        0
                      )}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </>
        }
      />
    </>
  );
};

export default TotalDeliveryReceiptSummaryPage;
